/* Target the modal's background, text color, and border */
.modal-content {
    background-color: #1a1a1a; /* Custom background color */
    color: #A6822E;  /* Custom text color */
    border: 2px solid #A6822E; /* Custom border around the modal content */
    border-radius: 8px;
    padding: 20px;
}
  
.modal-title {
    text-align: center;
    width: 100%;
    margin: 0;
    color: #A6822E;
}
  

.modal-content input[type="text"],
.modal-content input[type="email"],
.modal-content textarea {
  background-color: #333; 
  color: #FFF; 
  border: 1px solid #A6822E; 
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
}


.modal-content input::placeholder,
.modal-content textarea::placeholder {
  color: #A6822E;
}


.modal-content textarea {
  background-color: #333 !important;
  color: #FFF !important; 
  border: 1px solid #A6822E; 
  width: 100%;
  padding: 10px;
  resize: none; 
}


.modal-content input:focus,
.modal-content textarea:focus {
  background-color: #333;
  color: #FFF;
  border: 1px solid #A6822E;
  outline: none; 
}



.custom-alert {
    background-color: #333;
    color: #A6822E; 
    font-size: 1.2rem; 
    text-align: center;
    padding: 15px; 
    margin-bottom: 30px;
    width: 100%; 
    border: 2px solid #A6822E;
    border-radius: 8px; 
  }
  

  .success-message {
    padding: 20px;
    text-align: center;
    width: 100%;
  }
  
  .success-message button {
    background-color: #333;
    color: #fff; 
    border: 2px solid #A6822E;
    border-radius: 5px;
  }


  .custom-button {
    border: 2px solid #A6822E;
    background-color: #1a1a1a;
  }


textarea {
    background-color: #333; 
    color: #A6822E;
    resize: none;
    

    scrollbar-width: thin; 
    scrollbar-color: #A6822E #1a1a1a;
  }