/* Default (Desktop) Styles - Applied for screens larger than 1024px */
@media (min-width: 1025px) {


.gallery-container {
    display: flex;
    flex-wrap: wrap;  
    justify-content: space-between;  
    padding: 100px ;
  }
  

  .gallery-image {
    flex: 1 1 calc(50% - 20px);  
    margin-bottom: 20px;  
    padding: 20px 20px;
    border: solid #dbac34;
    box-sizing: border-box;
  }
  

  .gallery-image img {
    width: 100%;
    height: auto; 
    display: block;
    border-radius: 8px;  
  }

  .photographer-credit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(13, 13, 13, 0.8); 
    color: #dbac34;
    text-align: center;
    padding: 10px 0;
    z-index: 1000; 
  }
  
  .photographer-credit p {
    margin: 0;
    font-size: 1rem;
  }
  
  .photographer-credit a {
    color: #dbac34;
    text-decoration: underline;
  }
  
  .photographer-credit a:hover {
    color: #ffffff;
  }

}

  /* Tablet Styles (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .gallery-container {
    display: flex;
    flex-wrap: wrap;  
    justify-content: space-between;  
    padding: 30px;
  }
  
  /* Style each image container */
  .gallery-image {
    flex: 1 1 calc(50% - 20px); 
    margin-bottom: 20px; 
    padding: 20px 20px;
    border: solid #dbac34;
    box-sizing: border-box;
  }
  
  /* Image style */
  .gallery-image img {
    width: 100%;
    height: auto;  
    display: block;
    border-radius: 8px;  
  }

  .photographer-credit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(13, 13, 13, 0.8);
    color: #dbac34;
    text-align: center;
    padding: 10px 0;
    z-index: 1000; 
  }
  
  .photographer-credit p {
    margin: 0;
    font-size: 1rem;
  }
  
  .photographer-credit a {
    color: #dbac34;
    text-decoration: underline;
  }
  
  .photographer-credit a:hover {
    color: #ffffff;
  }
}
  
  @media (max-width: 768px) {
    .gallery-container {
      display: flex;
      flex-wrap: wrap; 
      justify-content: space-between;  
      padding: 40px;
    }
    
   
    .gallery-image {
      margin-bottom: 20px;  
      padding: 20px 20px;
      border: solid #dbac34;
      box-sizing: border-box;
    }
    
  
    .gallery-image img {
      width: 100%;
      height: auto; 
      display: block;
      border-radius: 8px;  
    }

    .photographer-credit {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(13, 13, 13, 0.8); 
      color: #dbac34; 
      text-align: center;
      padding: 10px 0;
      z-index: 1000; 
    }
    
    .photographer-credit p {
      margin: 0;
      font-size: 1rem;
    }
    
    .photographer-credit a {
      color: #dbac34;
      text-decoration: underline;
    }
    
    .photographer-credit a:hover {
      color: #ffffff;
    }
  }
  