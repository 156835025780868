
*, *::before, *::after {
  box-sizing: border-box;
}

/* Default (Desktop) Styles - Applied for screens larger than 1024px */
@media (min-width: 1025px) {
  body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    background-color: #0D0D0D;
    background-image: url('./Images/TheDukeDesktopBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .App {
    min-height: 100vh;
    text-align: center;
  }

  .navigation {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 20vh;
    padding: 15vh 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(13, 13, 13, 0.6); 
    z-index: 1000;
  }

  .main-content {
    margin-top: 1vh; 
    padding: 20px;
    background-color: transparent;
    z-index: 1;
  }

  .hamburger-menu {
    display: none;
  }

  .left-nav, .right-nav {
    flex-grow: 1;
    position: relative;
  }

  .center-nav {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    padding: 1.5vw 2vw;
  }

  .left-hr, .right-hr {
    border: 0.125rem solid #dbac34;
    width: 100%;
    opacity: 1;
  }

  
  .logo img {
    height: auto;
    width: 17vw;
    max-width: 20rem;
  }

  .nav-links-left, .nav-links-right {
    position: absolute;
    top: -2.5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 25%;
    align-items: center;
  }

  .nav-links-left a, .nav-links-right a {
    color: #dbac34;
    text-decoration: none;
    font-size: 1.8rem;
    transition: color 0.3s;
  }

  .nav-links-left a:hover, .nav-links-right a:hover {
    color: #fff;
    border: 2px solid #dbac34;
  }

  .faq-page {
    padding: 5vh 0;
    color: #dbac34;
    width: 35vw;
    margin: 0 auto;
  }

  .faq-item {
    margin-bottom: 10px;
    border: 1px solid #dbac34;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    background-color: #0D0D0D;
  }

  .faq-question {
    font-weight: bold;
    color: #dbac34;
  }

  .faq-answer {
    text-align: left;
    padding-top: 10px;
    color: #dbac34;
  }

  .contact-page {
    min-height: 100vh;
    margin: 0;
    padding: 20px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
  }

  .alert {
    margin: 20px auto;
    width: 50%;
  }

  .map-container {
    height: 50vh;
    width: auto;
    max-width: 80vw;
  }
}

/* Tablet Styles (769px - 1024px) ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Adjust tablet-specific styles */
  body {
    font-size: 15px;
    background-image: url('./Images/TheDukeDesktopBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    text-align: center;
  }

  .navigation {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20vh;
    padding: 0 2vw; /* Adjusted padding */
    background-color: rgba(13, 13, 13, 0.6);
    z-index: 1000;
    width: 100%;
  }

  .main-content {
    margin-top: 12vh; 
    padding: 20px;
    width: 100%;
  }

  .hamburger-menu {
    display: none;
  }

  .left-nav, .right-nav {
    flex-grow: 1;
    position: relative;
  }

  .center-nav {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    padding: 1.5vw 2vw;
  }

  .logo img {
    height: auto;
    width: 22vw;
    max-width: 20rem;
  }

  .nav-links-left, .nav-links-right {
    position: absolute;
    top: -1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15%;
    align-items: center;
  }

  .nav-links-left a, .nav-links-right a {
    color: #dbac34;
    text-decoration: none;
    font-size: 1rem; 
    transition: color 0.3s;
  }

  .nav-links-left a:hover, .nav-links-right a:hover {
    color: #fff;
    border: 2px solid #dbac34;
  }

  .left-hr, .right-hr {
    border: 0.125rem solid #dbac34;
    width: 100%;
    opacity: 1;
  }

  .faq-page {
    color: #dbac34;
    width: 60vw;
    margin: 0 auto;
  }

  .faq-item {
    margin-bottom: 10px;
    border: 1px solid #dbac34;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    background-color: #0D0D0D;
  }

  .faq-question {
    font-weight: bold;
    color: #dbac34;
  }

  .faq-answer {
    padding-top: 10px;
    color: #dbac34;
  }

  .contact-page {
    padding: 30px;
  }

  .map-container {
    height: 50vh;
    width: auto;
    max-width: 80vw;
  }
}

/* Mobile Styles (up to 768px) /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media (max-width: 768px) {

  html, body {
    font-size: 14px;
    padding: 0;
    margin: 0;
    background-image: url('./Images/TheDukeMobileBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    text-align: center;
  }

   
  .navigation {
    position: relative;  /* Containing block for absolute positioning */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    padding: 0;  /* Remove padding */
    margin: 0;   /* Remove margin */
    width: 100%;
  }

  .hamburger-menu {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    margin: 0;   /* Remove margins */
    padding: 0;  /* Remove padding */
  }

  .hamburger-icon {
    width: 30px;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hamburger-icon span {
    display: block;
    height: 4px;
    background-color: #dbac34;
    border-radius: 2px;
  }

  .navigation .logo {
    margin: 50px auto 0 auto;  /* Center horizontally, adjust top margin */
  }

  .navigation .left-nav,
  .navigation .right-nav {
    display: none;  /* Hide on mobile */
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 13, 13, 0.95); 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .mobile-menu a {
    color: #dbac34;
    font-size: 2rem;
    margin: 15px 0;
    text-decoration: none;
  }

  .mobile-menu a:hover {
    color: #fff;
    border: 2px white;
  }

  .navigation .left-nav,
  .navigation .right-nav {
    display: none;
  }

  .navigation {
    justify-content: center;
    padding: 10px 0; 
  }

  
  .navigation .logo img {
    width: 50vw;
    max-width: 70vw;
    height: auto;
  }

  .center-nav {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    padding: 1.5vw 2vw;
  }


  .main-content {
    margin-top: 0;
    padding: 20px;
    width: 100%;
  }

  .faq-page {
    color: #dbac34;
    width: 80vw;
    margin: 0 auto;
  }

  .faq-item {
    margin-bottom: 10px;
    border: 1px solid #dbac34;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    background-color: #0D0D0D;
  }

  .faq-question {
    font-weight: bold;
    color: #dbac34;
  }

  .faq-answer {
    padding-top: 10px;
    color: #dbac34;
  }


  .contact-page {
    padding: 15px;
    min-height: 100vh;
  }

  .map-container {
    height: 50vh;
    width: auto;
    max-width: 80vw;
  }
}

