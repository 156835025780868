
.banner-container {
  width: 100%;
  overflow: hidden;
  height: 80vh; 
}

.home-banner {
  display: block;
  margin: 0 auto;
  width: 90vw;
  max-width: none; 
  height: 100%;
  object-fit: cover; 
  border: double 5px #dbac34;
}

  @media (min-width: 1025px) {
    .info-box {
      border: double #dbac34;
      border-width: 5px;
      padding: 6vh 3vw;
      width: 40vw;
      margin: 8vh auto;
    }
  
    .info-text {
      color: #dbac34;
      font-size: larger;
    }

    .banner-container {
      height: 80vh; 
    }
  
    .home-banner {
      width: 90vw;
    }

    .contact{
      width: 30vw;
      margin: 0 auto;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .info-box {
        border: double #dbac34;
        border-width: 5px;
        width: 40vw;
        margin: 0 auto;
        margin-top: 1vh;
      }
  
    .info-text {
      color: #dbac34;
    }

    .banner-container {
      height: 70vh; 
    }
  
    .home-banner {
      width: 95vw; 
    }

    .contact{
      width: 30vw;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 768px) {
    .info-box {
        border: double #dbac34;
        border-width: 5px;
        padding: 3vh 3vw;
        width: 70vw;
        margin: 0 auto;
      }
  
    .info-text {
      color: #dbac34;
    }

    .banner-container {
      height: 60vh;
      margin-bottom: 20px; 
    }
  
    .home-banner {
      width: 88vw;
    }

    .contact{
      width: 50vw;
      margin: 0 auto;
    }
  }